import { isArray } from 'lodash'

export const addNotification = (notification) => {
	if (isArray(notification)) {
		return {
			type: 'ADD_NOTIFICATION',
			payload: notification,
		}
	} else {
		return {
			type: 'ADD_NOTIFICATION',
			payload: [notification],
		}
	}
}